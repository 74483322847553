@mixin gradient($from, $to) {
  /* Chrome */
  background: linear-gradient(to left, $from 40%, $to);

  /* Firefox 3.6+ */
  background: -moz-linear-gradient(to left, $from 40%, $to);

  /* Safari 4+, Chrome 1+ */
  background: -webkit-gradient(to left, $from 40%, $to);

  /* Safari 5.1+, Chrome 10+ */
  background: -webkit-linear-gradient(to left, $from 40%, $to);

  /* Opera 11.10+ */
  background: -o-linear-gradient(to left, $from 40%, $to);
}

main.ant-layout-content {
  padding-bottom: 0px;
}

.hero-map {
  transition: all 0.3s ease;

  &.map-show {
    opacity: 1;
  }

  &.map-hide {
    opacity: 0;
  }
}

.hero {
  margin-top: -50px;
  background: #f2f5fc;
  min-height: calc(100vh - 120px);

  .hero-content {
    padding-top: 125px;
    padding-bottom: 30px;

    h1.no-data {
      text-align: right;
      padding-right: 64px;
    }
  }

  .company-body {
    padding-right: 64px;
    margin-top: 25px;
    width: 100%;
  }
}

.company-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;

  .company-body {
    margin-top: 25px;
    margin-bottom: 40px;
    width: 100%;

    &.first {
      padding-bottom: 50px;
    }

    &.odd {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &.even {
      padding-top: 50px;
      padding-bottom: 50px;
      background: #f2f5fc;
    }

    .company-detail {
      h3 {
        font-weight: 600;
      }
    }
  }
}

.company-title {
  text-align: center;

  h2 {
    font-size: 29px;
    font-weight: 400;
    margin-bottom: 0;
  }

  span {
    font-size: 26px;
    font-weight: 600;
  }

  .crop-img {
    max-height: 85px;
    width: auto;
  }
}
