@import "~antd/dist/antd.css";

$primary: #2872c6;
$secondary: #0081e0;

body {
  margin: 0;
  font-family: "Gotham A", "Gotham B", Verdana, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.layout {
  height: 100%;
}

.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #2872c6;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  background: #2872c6;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.95);
  position: relative;

  &::after {
    background-color: rgba(255, 255, 255, 0.8);
    bottom: -5px;
    width: 0;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: width 0.15s ease-out;
  }
}

.ant-menu.ant-menu-dark.ant-menu-horizontal {
  .ant-menu-item-selected {
    background: $primary;

    a {
      &::after {
        width: 100%;
      }
    }
  }

  .ant-menu-item {
    &:hover {
      background: $primary;

      a {
        &::after {
          width: 100%;
        }
      }
    }
  }
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.logo {
  // float: left;
  margin: 8px 24px 10px 0;
  width: 100%;
  max-width: 150px;
  max-height: 37px;
  margin-right: 25px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-layout-content {
  background: #fff;
  padding-top: 100px;
  padding-bottom: 50px;
  min-height: calc(100vh - 72px);
}

.container {
  padding: 0 64px;
}

.icon-info {
  position: absolute;
  top: 10px;
  right: 10px;
}

.count-up-card {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  text-align: center;
  min-height: 183px;

  h3 {
    font-size: 28px;
    font-weight: 600;

    .unit {
      display: block;
      font-size: 12px;
      font-weight: normal;
    }
  }

  h4 {
    font-size: 12px;
    font-weight: 500;
  }

  &.red-card {
    box-shadow: 0px 0 30px rgba(141, 1, 1, 0.08);
    color: #8d0101;

    h3,
    h4 {
      color: #8d0101;
    }
  }
}

.footer {
  text-align: center;
}

.footer.fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
}

h1.no-data {
  color: #8f8f8f;
  margin: "auto";
  width: 100%;
  margin-top: 15%;
  text-align: center;
}

.source-link {
  word-break: break-all;
}

.info-divider {
  .ant-divider-horizontal.ant-divider-with-text {
    font-size: 14px;

    .ant-divider-inner-text {
      table {
        td {
          padding: 10px;
          border: solid 1px rgba(0, 0, 0, 0.06);
        }
      }
    }
  }
}

.image-capture {
  font-size: 20px;
  line-height: 40px;
  float: right;
}

.capture-block {
  padding-top: 53px;
  background-color: #fff;
}

.hide {
  display: none;
}
