.title-wrapper {
  text-align: center;
  background: url("https://www.idhsustainabletrade.com/uploaded/2015/11/tea-sector1-1440x450-c-default.jpg");
  background-size: cover;
  margin-top: -40px;
  padding-top: 150px;
  padding-bottom: 150px;

  h1 {
    color: #ffffff;
    font-size: 56px;
    line-height: 1.15625;
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    color: #ffffff;
    font-size: 24px;
  }
}

.content-wrapper {
  padding: 0 160px;
  margin: 80px 0;

  &.odd {
    background: #0081e0;
    color: #ffffff;

    &.meta-wrapper {
      padding-top: 30px;

      .meta-left {
        margin: 30px 0;
        text-align: left;
      }

      .meta-right {
        margin: 20px 0;
        text-align: right;
      }

      .meta-body {
        text-align: center;
      }

      .meta-symbol {
        font-size: 30px;
      }

      .meta-img {
        filter: brightness(0) invert(0);
      }
    }
  }

  &.even {
    background: #2871c6;
    text-align: right;
    color: #fff;

    h3,
    h4 {
      color: #fff;
    }

    &.meta-wrapper {
      padding-top: 25px;
      padding-bottom: 25px;

      .meta-left {
        margin: 50px 0;
        text-align: left;
      }

      .meta-right {
        margin: 50px 0;
        text-align: right;
      }

      .meta-body {
        text-align: center;
      }

      .meta-symbol {
        font-size: 30px;
      }

      .meta-img {
        filter: brightness(0) invert(1);
      }

      &.meta-secondary {
        margin-top: -80px;
        background-color: #0081e0;
      }
    }
  }

  &.bg-white {
    background: #fff;
    color: #000000;

    h3 {
      color: #2871c6;
    }
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
  }

  h4 {
    font-size: 14px;
    line-height: normal;
  }

  .img-title {
    position: relative;
    text-align: center;
    cursor: pointer;

    img {
      border-radius: 14px;
      transition: all 0.25s ease-out;
    }

    h3 {
      position: absolute;
      margin: auto;
      height: 64px;
      width: calc(100% - 24px);
      height: auto;
      color: #fff;
      top: calc(50% - 64px);
      bottom: 0;
    }

    &:hover {
      img {
        /**
                                                        * Previously 0.3
                                                        **/
        filter: brightness(1);
      }
    }
  }

  .col-block {
    height: 225px;

    h4 {
      color: #ffffff;
      margin: auto;
      top: 50%;
      position: absolute;
      width: 100%;
    }
  }
}
