.hero-wrapper::before {
  content: "";
  background-image: url("https://www.idhsustainabletrade.com/uploaded/2020/08/2020_farmland-2_indonesia_Copyright-Pexels-Tom-Fisk_YCU-1440x400-c-default.jpg");
  background-size: cover;
  position: absolute;
  top: 60px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 194px;
  opacity: 0.5;
}

.hero-wrapper {
  padding: 25px 0;
  width: 100%;
  min-height: 182px;
  margin-top: -35px;
  background-color: #000;

  .container {
    margin: auto;

    .hero-body {
      color: #fff;
      text-align: center;

      h3 {
        font-size: 32px;
        color: #fff;
        font-weight: 700;
        text-shadow: 0 0 17px black;
      }

      p {
        font-size: 18px;
        text-shadow: 0 0 6px black;
      }
    }
  }
}

.idt-wrapper {
  margin: 25px 0 90px 0;
}
