.map-wrapper {
  position: relative;
  background-color: #f4fdff;
  margin-top: -32px;
  min-height: calc(100vh - 140px);

  .map-buttons {
    position: absolute;
    z-index: 1;
    top: 32px;

    button {
      margin-left: 10px;
    }
  }

  .map-legend {
    content: "";
    z-index: 1;
    height: 14px;
    width: 160px;
    position: absolute;
    bottom: 64px;
    margin: auto;
    color: #fff;
    text-align: center;
    letter-spacing: normal;

    span {
      margin: auto;
      display: block;
      color: #222;
      margin-top: -18px;
      background: #f2f2f2;
      font-size: 12px;
      line-height: 18px;
    }

    &::before {
      content: "min";
      position: absolute;
      top: -3.5px;
      left: 10px;
      font-size: 12px;
    }

    &::after {
      content: "max";
      position: absolute;
      top: -3.5px;
      right: 10px;
      font-size: 12px;
    }
  }

  .react-tooltip {
    padding: 0px;
    opacity: 1;

    .map-tooltip {
      min-width: 150px;
      margin: 0;
      background-color: #2872c6;
      color: #fff;

      h3 {
        background-color: #0081e0;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        white-space: pre-wrap;
        margin: auto;
        padding: 10px;
        text-align: center;
      }

      h4 {
        font-size: 14px;
        font-weight: 600;
        white-space: pre-wrap;
      }

      ul {
        padding: 10px;
        margin: 0;

        li {
          display: flex;
          justify-content: space-between;

          &:not(:last-child) {
            border-bottom: 1px solid #ddd;
            padding-bottom: 3px;
            margin-bottom: 3px;
          }
        }
      }
    }
  }
}
