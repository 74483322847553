.compare-options-wrapper {
  margin-bottom: 75px;

  .compare-options-body {
    .text {
      margin-right: 10px;
      font-size: 15px;
    }
  }
}

.compare-title {
  margin-top: -30px;
  padding-bottom: 30px;
  text-align: center;
}

.compare-title-separator {
  padding-bottom: 30px;
  text-align: center;
}

.compare-wrapper {
  margin-top: 25px;
  margin-bottom: 70px;
  background-color: #fff;

  .compare-body {
    margin-top: 25px;
    margin-bottom: 50px;

    h3 {
      font-size: 22px;
      font-weight: 500;
    }

    .compare-card {
      box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
      text-align: center;

      .ant-card-body {
        padding: 0px;
      }

      h3 {
        font-size: 26px;
        font-weight: 600;

        .unit {
          display: block;
          font-size: 12px;
          font-weight: normal;
        }
      }

      h4 {
        font-size: 16px;
        font-weight: 500;

        .unit {
          display: block;
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }
}

.ant-card-grid.red-card {
  color: #8d0101;

  h3,
  h4 {
    color: #8d0101;
  }
}
